<template>
  <div>
    <page-header :title="title" :menu="menu" />
    <v-card>
      <v-card-text>
        <v-container>
          <br />
          <br />
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-row class="d-flex justify-end">
                <v-col cols="12" sm="4" class="mr-n4">
                  <v-switch
                    v-model="product.stockControl"
                    label="Controlar Estoque"
                  ></v-switch>
                </v-col>

                <v-col cols="4" class="mt-2" v-if="product.stockControl">
                  <v-text-field
                    outlined
                    v-model="product.stock"
                    label="Estoque"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="mt-n4">
                  <span>Preço Venda</span>
                  <money
                    class="money"
                    v-model="product.price"
                    v-bind="money"
                  ></money>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="product.name"
                  label="Nome"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :items="categories"
                  v-model="product.categoryId"
                  label="Selecionae uma Categoria*"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="product.manufacturer"
                  label="Fabricante"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8" class="mt-n8">
                <v-textarea
                  outlined
                  label="Descrição Completa"
                  v-model="product.description"
                  rows="7"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="mt-n8">
                <v-btn text class="black--text" @click.native="openFileDialog">
                  {{ fileName ? fileName : "Imagem" }}
                  <v-icon right dark>mdi-camera</v-icon>
                </v-btn>
                <input
                  ref="fileRef"
                  type="file"
                  id="file-upload"
                  style="display: none"
                  @change="handleFileUpload"
                />
                <v-img
                  height="160"
                  width="160"
                  :src="urlPreview"
                  @click.native="openFileDialog"
                ></v-img>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text to="/base/products">
                Cancelar
              </v-btn>
              <v-btn
                dark
                :disabled="!valid"
                :loading="loading"
                width="200px"
                color="#2ca01c"
                @click="validate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  name: "ProductsForm",
  components: { Money, PageHeader },
  data: () => ({
    valid: true,
    title: "Cadastro de Produto",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/base/products",
      },
      {
        text: "Cadastrar Produto",
        disabled: true,
        href: "/base/products",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    products: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview:
      "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      name: "",
      description: "",
      stock: 2,
      categoryId: "",
      price: 0.0,
      imageUrl: "",
      productType: "PRODUTO",
      promotionalPrice: 0.0,
      costPrice: 0.0,
      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      unity: "UN",
      stockControl: true,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    async getItem() {
      const productId = this.$route.params.id;
      if (productId) {
        this.title = "Editar Produto";
        this.menu[2].text = "Editar Produto";
        await http.get(`products/${productId}`).then(async (res) => {
          const { data } = await res;
          this.product = data;
          this.productId = data.id;
          this.urlPreview = data.imageUrl;
        });
      }
    },
    getCategories() {
      http.get("categories").then((res) => {
        this.categories = res.data;
      });
    },

    async registerOrUpdate() {
      this.loading = true;
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        await http.post("upload-file", formData).then(
          (d) => {
            this.product.imageUrl = d.data;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      this.product.promotionalPrice = this.product.price;
      if (this.productId) {
        await http.put(`products/${this.productId}`, this.product).then(
          () => {
            this.snackbar.text = "Produto atualizado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/base/products");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao atualizar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      } else {
        await http.post("products", this.product).then(
          () => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/base/products");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
